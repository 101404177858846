<template>
  <div class="about" data-title="用户登录" v-title>
    <el-card class="jue-card">
      <div class="title">
        <img src="https://api.qk.zhongheinfo.com/attachment/default/avatar/20200527/5ece2e7a20927.png" class="logo">

        <span>潜客通</span>
      </div>
      <el-form
        :model="form"
        :rules="rules"
        class="regForm"
        label-width="100px"
        ref="form"
        status-icon
      >
        <el-form-item label="站点密钥" v-if="hideSite">
          <el-input placeholder="请输入密钥" v-model="site"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input
            autocomplete="off"
            placeholder="注册时填写的手机号码"
            type="text"
            v-model.number="form.mobile"
          >
            <template slot="prepend">+86</template>
          </el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input autocomplete="off" placeholder="请输入密码" type="password" v-model="form.password" @keyup.enter.native="subLogin('form')"></el-input>
        </el-form-item>
        <el-form-item label-width="0px">
          <el-button @click="subLogin('form')" class="submitForm" type="primary">确认登录</el-button>
          <div class="other">
            <div class="o-1"></div>
            <a href="javascript:;">忘记密码？请联系管理员</a>
          </div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data() {
    var checkMobile = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号码不能为空'))
      } else {
        const reg = /^1\d{10}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号码'))
        }
      }
    }
    return {
      hideSite: true,
      site: localStorage.getItem('x-auth-site') || '',
      form: {
        mobile: '',
        password: ''
      },
      rules: {
        mobile: [{ validator: checkMobile, trigger: 'blur' }],
        password: [{ required: true, message: '密码没有输入', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    if(location.host.split('.qk.jue.sh').length == 2){
      localStorage.setItem('x-auth-site', location.host.split('.qk.jue.sh')[0])
      this.hideSite = false
    }

    if (localStorage.getItem('x-auth-token')) {
      this.$router.push({
        name: 'console'
      })
    }

  },
  methods: {
    subLogin(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios.post('/user/login', this.form).then(res => {
            if (res.data.code == 200) {
              localStorage.setItem('x-auth-site', res.data.data.site)
              localStorage.setItem('x-auth-token', res.data.data.token)
              localStorage.setItem('x-auth-list', res.data.data.auth_list)
              // localStorage.setItem('userinfo', JSON.stringify(res.data.data))

              //登录后
              this.$store.commit('set_user', res.data.data)

              this.$message.success(res.data.msg)
              let redirect = this.$route.query.redirect
                ? this.$route.query.redirect
                : '/'
              this.$router.push({ path: redirect })
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })
    }
  },
  watch: {
    site(){
      localStorage.setItem('x-auth-site', this.site)
    }
  }
}
</script>
<style lang="less" scoped>
.about {
  background-image: url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg);
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jue-card {
  width: 500px;
  padding: 20px 40px;
}
.title {
  font-size: 24px;
  font-weight: bolder;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
}
.regForm {
  /deep/ .el-form-item:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 45px;
    &.is-error {
      border-bottom: 1px solid #f56c6c;
    }
    /deep/ .el-button {
      display: block;
      text-align: right;
      width: 100%;
    }
  }
  /deep/ .el-input__inner {
    border: none;
  }
  /deep/ .el-form-item__label {
    text-align: left;
    font-weight: bold;
  }
  /deep/ .el-form-item__error {
    padding: 5px 15px;
  }
  /deep/ .el-input-group__prepend {
    border: none;
    background: none;
  }
  .submitForm {
    width: 100%;
  }
}

.other {
  display: flex;
  font-size: 12px;
  .o-1 {
    flex: 1;
    align-items: center;
  }
}
</style>
